<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl"
        >Rekonsiliasi Faktur Pembelian dengan FP Masukan</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"
          >/ Pajak / Rekonsiliasi Faktur Pembelian dengan FP Masukan
        </span>
      </div>
    </div>

    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          @click="onExport()"
        />
      </div>
      <grid-rekonsiliasi-pembelian
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
      />
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import RekonsiliasiPembelianService from '@/services/RekonsiliasiPembelianService'
import GridRekonsiliasiPembelian from '@/components/pajak/GridRekonsiliasiPembelian'
import errorHandler from '@/helpers/error-handler'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    GridRekonsiliasiPembelian,
  },
  data() {
    return {
      dataService: null,
      isLoading: false,
      isLoadingExport: false,
      items: [],
      invoices: [],
      selects: null,
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
      submitted: false,
      form: {
        id: 0,
        date_from: null,
        date_to: null,
      },
    }
  },
  created() {
    this.dataService = new RekonsiliasiPembelianService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      let filters = this.options.filters
      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Rekonsiliasi Masukan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.dataService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'rekonsiliasi-masukan.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Rekonsiliasi Masukan', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
  },
  validations() {
    return {
      form: {
        date_from: {
          required: helpers.withMessage(
            'Tanggal (dari) harus diisi.',
            required
          ),
        },
        date_to: {
          required: helpers.withMessage('Tanggal (s/d) harus diisi.', required),
        },
      },
    }
  },
}
</script>
